export default {
  SET_REPORT_INFO(state, payload) {
    state.reportInfo = payload
  },
  SET_ACTIVE_TOKEN(state, payload) {
    state.activeToken = payload
  },
  SET_ACTIVE_TYPE(state, payload) {
    state.activeType = payload
    state.activeRowName = null
  },
  SET_ACTIVE_FUNDS(state, payload) {
    state.activeFunds = payload
  },
  SET_ACTIVE_SORT(state, payload) {
    state.activeSort = payload
  },
  SET_ACTIVE_ROW_NAME(state, payload) {
    state.activeRowName = payload
  },
  SET_PURCHASED_INFO(state, payload) {
    state.purchasedInfo = payload
  },
  SET_CURRENT_PRICES(state, payload) {
    state.currentPrices = payload
  },
  SET_SOF_TRANSACTIONS(state, payload) {
    state.sofTransactions = payload
  },
  SET_UOF_TRANSACTIONS(state, payload) {
    state.uofTransactions = payload
  },
  SET_SOF_EXPOSURE_HEADER(state, payload) {
    state.sofExposureHeader = payload
  },
  SET_UOF_EXPOSURE_HEADER(state, payload) {
    state.uofExposureHeader = payload
  },
  SET_SOF_EXPOSURE(state, payload) {
    state.sofExposure = payload
  },
  SET_UOF_EXPOSURE(state, payload) {
    state.uofExposure = payload
  },
  SET_SOF_CURRENCIES(state, payload) {
    state.sofCurrencies = payload
  },
  SET_UOF_CURRENCIES(state, payload) {
    state.uofCurrencies = payload
  },
}
