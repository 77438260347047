const getters = {
  getReportInfo: state => state.reportInfo,
  getActiveToken: state => state.activeToken,
  getActiveType: state => state.activeType,
  getActiveFunds: state => state.activeFunds,
  getActiveSort: state => state.activeSort,
  getActiveRowName: state => state.activeRowName,
  getPurchasedInfo: state => state.purchasedInfo,
  getCurrentPrices: state => state.currentPrices,
  getSofTransactions: state => state.sofTransactions,
  getUofTransactions: state => state.uofTransactions,
  getSofExposureHeader: state => state.sofExposureHeader,
  getUofExposureHeader: state => state.uofExposureHeader,
  getSofExposure: state => state.sofExposure,
  getUofExposure: state => state.uofExposure,
  getSofCurrencies: state => state.sofCurrencies,
  getUofCurrencies: state => state.uofCurrencies,
}

export default getters