import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import appConfig from '@/utils/appConfig'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing-page',
    component: () =>
      import(/* webpackChunkName: "landing-page" */ '../pages/landing-page'),
    meta: {
      requiresAuth: false,
      title: 'Report',
      showReportAbuseBanner: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../pages/login'),
    meta: {
      title: 'Login',
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () =>
      import(/* webpackChunkName: "register" */ '../pages/register'),
    meta: {
      title: 'Register',
    },
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () =>
      import(/* webpackChunkName: "reset-password" */ '../pages/register'),
    meta: {
      title: 'Reset password',
    },
  },
  {
    path: '/confirm',
    name: 'confirm',
    component: () =>
      import(/* webpackChunkName: "confirm" */ '../pages/register'),
    meta: {
      title: 'Confirm',
    },
  },
  {
    path: '/report',
    name: 'report',
    component: () => import(/* webpackChunkName: "report" */ '../pages/report'),
    meta: {
      requiresAuth: true,
      title: 'Report',
      showReportAbuseBanner: true,
    },
  },
  {
    path: '/source-of-funds',
    name: 'source-of-funds',
    component: () =>
      import(
        /* webpackChunkName: "source-of-funds" */ '../pages/source-of-funds'
      ),
    meta: {
      requiresAuth: true,
      title: 'Source of funds',
      showReportAbuseBanner: true,
    },
  },
  {
    path: '/your-reports',
    name: 'your-reports',
    component: () =>
      import(/* webpackChunkName: "your-reports" */ '../pages/your-reports'),
    meta: {
      requiresAuth: true,
      title: 'Your Reports',
      showReportAbuseBanner: true,
    },
  },
  {
    path: '/report-abuse',
    name: 'report-abuse',
    component: () =>
      import(/* webpackChunkName: "report-abuse" */ '../pages/report-abuse'),
    meta: {
      requiresAuth: false,
      title: 'Report Abuse',
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () =>
      import(/* webpackChunkName: "payment" */ '../pages/payment'),
    meta: {
      requiresFeatureToggle: 'FEATURE_PAYMENT',
      requiresAuth: true,
      title: 'Payment',
      showReportAbuseBanner: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () =>
      import(/* webpackChunkName: "profile" */ '../pages/profile'),
    meta: {
      requiresAuth: true,
      title: 'My Account',
      showReportAbuseBanner: true,
    },
  },
  {
    path: '/admin-panel',
    name: 'admin-panel',
    component: () =>
      import(/* webpackChunkName: "admin-panel" */ '../pages/admin-panel'),
    meta: {
      requiresAuth: true,
      requiresAdmin: true,
      title: 'Admin panel',
      showReportAbuseBanner: true,
    },
    redirect: '/admin-panel/users',
    children: [
      {
        path: 'users',
        name: 'admin-panel-users',
        component: () =>
          import(/* webpackChunkName: "admin-panel-users" */ '../pages/admin-panel/users'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          title: 'Users',
          showReportAbuseBanner: true,
        },
      },
      {
        path: 'promo',
        name: 'admin-panel-promo',
        component: () =>
          import(/* webpackChunkName: "admin-panel-promo" */ '../pages/admin-panel/promo'),
        meta: {
          requiresAuth: true,
          requiresAdmin: true,
          title: 'Promo',
          showReportAbuseBanner: true,
        },
      },
    ],
  },  
  {
    path: '/terms-of-use',
    name: 'terms-of-use',
    component: () =>
      import(/* webpackChunkName: "terms-of-use" */ '../pages/terms-of-use'),
    meta: {
      requiresAuth: false,
      title: 'Terms of use',
      showReportAbuseBanner: true,
    },
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () =>
      import(/* webpackChunkName: "privacy-policy" */ '../pages/privacy-policy'),
    meta: {
      requiresAuth: false,
      title: 'Privacy policy',
      showReportAbuseBanner: true,
    },
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy',
    component: () =>
      import(/* webpackChunkName: "privacy-policy" */ '../pages/cookie-policy'),
    meta: {
      requiresAuth: false,
      title: 'Cookie Policy',
      showReportAbuseBanner: true,
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../pages/404'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.commit('adminPanel/SWITCH_FILTER_CLEAR_TRIGGER')
  store.commit('report/SET_ACTIVE_ROW_NAME', null)

  if (to.name === 'payment' && to.query.promo) {
    localStorage.setItem('promocode', to.query.promo)
  }

  const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)

  if (requiresAdmin && !store.getters['user/isAdmin']) {
    next({ name: 'source-of-funds' })
    return
  } 

  if (to.name === 'login' && store.getters['user/userRole']) {
    next({ name: 'source-of-funds' })
    return
  } 

  if (to.name === 'source-of-funds' && from.name != 'login' && !store.getters['user/userRole']) {
    next({ name: 'login' })
    return
  } 

  if (to.meta.requiresFeatureToggle) {
    const featureToggleValue = appConfig[`VUE_APP_${to.meta.requiresFeatureToggle}`]
    if (!featureToggleValue) {
      next({ name: 'source-of-funds' })
      return
    }
  } 

  if (to.name === 'register') {
    await store.dispatch('user/logout')
    next()
    return
  } 

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('cookie:accepted')) {
      localStorage.setItem('to.fullPath', to.fullPath)
    }

    const token = await store.dispatch('user/initToken')
    if (!token) {
      next({ name: 'login' })
      return
    }

    const isRejected = await store.dispatch('user/getMe')
    if (isRejected) return
  } 

  next()
})


router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'gl-front-vision'

    const existPromo = localStorage.getItem('promocode')

    if (to.name == 'payment' && existPromo) {
      store.dispatch('adminPanel/redeemCode', existPromo)
      localStorage.removeItem('promocode')
    }
  })
})

router.onError(error => {
  console.error(error)
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload()
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router
