
export default {
  reportInfo: {},
  activeToken: {},
  activeType: 'riskLabels',
  activeFunds: 'full',
  activeSort: null,
  activeRowName: null,
  purchasedInfo: {},
  currentPrices: {},
  sofExposureHeader: {},
  uofExposureHeader: {},
  sofExposure: {},
  uofExposure: {},
  sofTransactions: {},
  uofTransactions: {},
  sofCurrencies: {},
  uofCurrencies: {},
}
